.container {
    width: 100%;
    border-radius: 5px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f2f2f2;
}

.headerDescriptionContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.headerDescription {
    color: #8c8c8c;
}

.headerDetailsSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitleHeader {
    font-size: 14px;
    color: grey;
}