.header {
    color: grey;
    line-height: 14px;
}

.value {
    font-weight: bold;
}

.summerySectionCell {
    display: table-cell;
    padding: 10px;
}

.summerySectionCellContent {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}