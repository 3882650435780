.container {
    background-color: white;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    &.clickable {
        cursor: pointer;
    }
}

.text {
    font-size: 14px;
    color: #5f6d7b;
    font-weight: 700;
    text-transform: uppercase;
    &.active {
        color: black;
        font-size: 16px;
    }
}

.desc {
    font-size: 10px;
    line-height: 1;
    font-style: italic;
    color: #797777;
}
