.container {
    min-width: 220px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.link {
    &:hover {
        text-decoration: none;
    }
}

.tabWrapper {
    margin-bottom: 3px;
}

.bottomWrapper {
    padding: 20px;
    background-color: white;
    height: 200px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topRow {
    margin-bottom: 10px;
}

.bottomRow {
    display: flex;
    justify-content: center;
    > div {
        margin: 0 10px;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.45);
    display: grid;
    justify-content: center;
    align-items: center;
}
