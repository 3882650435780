.appBar {
    position: relative;
    height: 8%;
}

.toolbar {
    height: 100%;
    width: 100%;
    padding: 0 40px 0 40px;
}

.innerWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: min-content 1fr min-content min-content min-content;
    align-items: center;
}