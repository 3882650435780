.container {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: 5px;
    grid-auto-flow: column;
}

.box {
    border: 1px solid #777777;
    outline: 0;
    height: 14px;
    width: 14px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    position: relative;

    &.isChecked {
        background-color: #59A651;
        border: 0;
    }
}

.icon {
    height: 12px;
    width: 12px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-8deg);
}
    
.label {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
}