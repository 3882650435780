.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 336px;
}

.dataWrapper {
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 3px;
  background-color: white;
  flex: 1;
}

.bottomWrapper {
  padding: 20px;
  background-color: white;
  flex: 1;
  display: grid;
  align-content: center;
  grid-row-gap: 24px;
}

.headline {
  color: black;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
}
