$max-size: 450px;

.conversationContent {
    width: 100%;
    height: $max-size;
    max-height: $max-size;
    padding-bottom: 0;
}

.conversationContentText {
    height: 10%;
}