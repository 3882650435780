.container {
    height: 100vh;
    min-height: 700px;
    display: flex;
    flex-direction: column;
}

.pageContainer {
    height: 82%;
    overflow-y: scroll;
}

.contentWrapper {
    padding: 0 40px 0 40px;
    width: 100%;
    height: 100%;
}