.container {
    padding: 20px 30px 30px;
    font-size: 16px;
    line-height: 26px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content auto;
    grid-column-gap: 20px;
}

.vehicleWrapper {
    display: grid;
    grid-row-gap: 12px;
    align-content: start	;
}

.vehicleImageWrapper {
    background-color: #DDDEDF;
    width: 140px;
    height: 100px;
}

.buttonSymbol {
    margin-right: 6px;
    position: static;
}

.button {
    color: #223A78;
    font-weight: 700;
    padding: 6px;
    background-color: #DDDEDF;
    font-size: 12px;
    width: 130px;
    justify-content: flex-start;
}

.grid {
    align-self: start;
}
