.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.number {
  font-size: 58px;
  line-height: 1;
  font-weight: 700;
}
.text {
  font-size: 20px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
}
