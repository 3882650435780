.accountContainer {
    display: flex;

    .accountInfo {
        display: grid;
        text-align: right;
        width: max-content;

        span:nth-child(odd) {
            font-size: 14px;
        }

        span:nth-child(even) {
            font-size: 12px;
        }
    }

    .accountMenu {
        display: inline-flex;
        align-items: center;
        margin-left: 12px;
        cursor: pointer;
    }
}

.accountDetailsContainer {
    display: inline-flex;
    padding: 10px;
    gap: 10px;
    align-items: center;

    svg {
        font-size: xxx-large;
        color: grey;
    }

    > div {
        display: grid;
    }

    div:nth-child(2) {
        line-height: 1.4;
    }

    span:nth-child(1) {
        font-size: 16px;
        font-weight: 1000;
    }

    span:not(:first-child) {
        font-size: 14px;
        font-weight: 400;
    }
}