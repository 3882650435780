@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "assets/styles/_colors";

:root {
    --baseFont: "Roboto", sans-serif;
    --bg-color-light-gray: #ebebeb;
    --color-blue: #0C2B69;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: var(--baseFont);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media print {
        visibility: hidden;
    }
}

a {
    color: $sleaDkBlue;
}

// GLOBAL STYLES
.page-header {
    margin: 0;
    margin-bottom: 10px;
    h1 {
        margin: 0;
        font-size: 26px;
    }
}

.content-area {
    min-height: 85vh;
    position: relative;
}

.btn {
    font-size: 12px;
}

span.help-block {
    color: #a94442;
}

.ct-series-a .ct-slice-donut {
    stroke: #263b77;
}

.ct-series-b .ct-slice-donut {
    stroke: #59a651;
}

.ct-series-c .ct-slice-donut {
    stroke: #e38032;
}

.ct-series-d .ct-slice-donut {
    stroke: #bbbbbb;
}

.icon-container {
    position: absolute;
    left: 50%;
    top: 50%;
}