.clearContainer {
    grid-column-end: span 4;
    border: none;
    cursor: pointer;
}

.clearContents {
    display: inline-flex;

    span {    
        align-items: center;
        display: inherit;
        font-size: 11px;
    }
}