.container {
    display: grid;
    padding: 2px 10px;
    grid-template-columns: 44px 44px 100px 44px 44px;
    border-top: 1px solid #aaaaaa;
    align-items: center;
    justify-content: center;
}

.progressWrapper {
    text-align: center;
    user-select: none;
}