.container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.titleWrapper {
    color: white;
    background-color: #3A3560;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    padding: 6px 8px;
}

.contentWrapper {
    background-color: white;
    min-height: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
}