.container {
    > input {
        &:checked ~ .checkbox {
            border: 0;
            background-color: #53A73E;          
        }
    }

    > .checkbox {
        border: 2px solid #797877;
    }
}
    