.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #737373;
    display: grid;
    justify-items: center;
    align-content: center;
    row-gap: 20px;
    padding: 20px;
}

.text {
    color: white;
    user-select: none;
    text-align: center;
}