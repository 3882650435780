.messageSendFormContainer {
    height: calc(40% - 20px);
}

.messageSendFormContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.messageInput {
    height: 70%;
}