.container {
    position: relative;
    width: min-content;

    > label {
        font-size: 16px;
        line-height: 1;
        cursor: pointer;
        padding-left: 14px;
        display: block;
        min-height: 14px;
        margin: 0;

        >span {
            padding-left: 5px;
        }
    }
    
    > input {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        overflow: visible;
        padding: 0;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;
        position: absolute;

        &:checked ~ .checkbox {
            > .icon {
                display: block;
            }
        } 
    }
    
}

.checkbox {
    background-color: transparent;
    height: 14px;
    width: 14px;
    cursor: pointer;
    border: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    margin: 0;
}

    
.icon {
    display: none;
    z-index: 1;
    pointer-events: none;
}
