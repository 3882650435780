.container {
    display: flex;
    flex-direction: column;
}

.body {
    background-color: #fff;
    height: 160px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    padding: 20px;
    flex: 1;
    position: relative;
    overflow: hidden;
}

.textWrapper {
    display: grid;
    grid-template-columns: 10px auto;
    align-items: center;
    grid-column-gap: 7px;
    width: 260px;
    > .dot {
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background-color: black;
        &.blue {
            background-color: #263b77;
        }
        &.green {
            background-color: #59a651;
        }
        &.orange {
            background-color: #e38032;
        }
    }
    > .text {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        &.blue {
            color: #263b77;
        }
        &.green {
            color: #59a651;
        }
        &.orange {
            color: #e38032;
        }
    }
}

.dataViewWrapper {
    width: 100%;
}
