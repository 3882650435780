.row {
    display: flex;
    flex-direction: row;
    min-height: 35px;
    max-height: 35px;
    font-size: 13px;
    align-items: center;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    min-height: 42px;
    max-height: 42px;
    font-size: 13px;
    align-items: center;
    font-weight: 500;
    border-style: solid;
    border-width: 1px 0;
    border-color: lightgray;
    background-color: #f2f2f2;
}

.cell {
    display: flex;
    min-height: inherit;
    max-height: inherit;
    overflow: hidden;
    border-bottom: 1px solid;
    border-color: lightgray;
    align-items: center;
    padding: 0 10px 0 0;
}

.row:hover > .cell { background-color: #f2f2f2;}