.container {
    top: 0;
    z-index: 1;
    font-weight: 700;
    position: relative;
    display: grid;
    align-items: center;
    border-bottom: 1px solid #aaaaaa;
    background-color: #fff;
}

.text {
    user-select: none;
}

.contentWrapper {
    padding: 5px 25px 5px 10px;
    display: grid;
    grid-row-gap: 4px;
}

.resizer {
    display: block;
    width: 6px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    //prevents scrolling while dragging on touch devices
    touch-action:none;
    cursor: ew-resize;
    display: flex;
    flex-direction: column;
    padding: 6px 0;

    > div {
        flex: 1;
        border-left: 2px solid #aaaaaa;
        border-right: 2px solid #aaaaaa;
    }
}

.iconCell {
    align-self: stretch;
    display: grid;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    min-width: 44px;
}