.ct-series-a {
  .ct-line,
  .ct-point {
    stroke: #263b77;
  }
}
.ct-series-b {
  .ct-line,
  .ct-point {
    stroke: #59a651;
  }
}
.ct-series-c {
  .ct-line,
  .ct-point {
    stroke: #e38032;
  }
}
