.container {
    border: 1px solid #aaaaaa;
    background-color: white;
    position: relative;
    font-size: 14px;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    height: 90%;
}

.grid {
    //resets the stacking context
    z-index: 0;
    row-gap: 10px;
    position: relative;
    padding-bottom: 10px;
}

.cell {
    background-color: #fff;
    display: grid;
    align-items: center;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .25);
    display: grid;
    align-items: center;
    justify-content: center;
}