.container {    
    padding: 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
}

.label {
    color: #7C736D;
    margin-right: 10px;
    margin-left: 10px;
}

.button {
    background-color: #DDDEDF;
    color: #797877;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    padding: 3px 8px;
    margin-right: 5px;
}

.input {
    background-color: transparent;
    color: #797877;
    border: 2px solid #DDDEDF;
    font-size: 10px;
    margin-right: 8px;
    padding: 1px;
}

.buttonClear {
    background-color: transparent;
    color: #223A7A;
    font-size: 14px;
}