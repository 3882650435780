.container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 90%;
}

.messagingContainer {
    height: 60%;
}

.messageHistory {
    height: 100%;
}