$font-color: #68686a;

.container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}

.imageWrapper {
    padding: 50px 50px 0 50px;
}

.image {
    width: 100%;
    margin-bottom: 15px;
}

.text {
    font-size: 18px;
}

.footer {
    margin-top: 20px;
    font-size: 12px;
}

.text, .footer {
    text-align: center;
    color: $font-color
}

.errorText {
    color: red;
}

.form {
    max-width: 300px;
}

.formWrapper {
    display: grid;
    max-width: 300px;
    row-gap: 20px;
    padding: 30px;
    margin-top: 20px;
}