.container {
    margin-top: 20px;
    background-color: #E3DEDB;
    padding: 10px 20px;
}

.innerWrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.headingWrapper {
    padding: 6px 10px;
}

.headline {
    font-size: 18px;
    line-height: 1;
    color: #223A78;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
}

.infoWrapper {
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-column-gap: 15px;
    margin-bottom: 15px;
}

.mainContentWrapper {
    display: grid;
    grid-row-gap: 15px;
}