.flexContainer {
    display: flex;
    width: 100%;
}

.wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.labelSection {
    flex-direction: row;
    justify-content: space-between;
}