.infoWrapper {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
}

.icon {
    position: static;
}

.actionsWrapper {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
}

.toggleButton {
    border: 1px solid #AAB0B7;
    border-radius: 2px;
    height: 24px;
    width: 24px;
}
