.container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.centerContent {
    display: grid;
    justify-content: center;
    align-items: center;
}