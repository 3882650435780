@media print {
    .container {
        visibility: visible;
    }
    .buttonsWrapper {
        visibility: hidden;
    }
}

.canvasWrapper {
    display: grid;
    justify-content: center;
    margin-top: 15px;
}

.statusText {
    >span {
        font-weight: 700;
    }
}

.iframe {
    border: 0;
    width: 100%;
    min-height: 500px;
}