.container {
    background-color: #dddedf;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 340px auto;
    grid-gap: 20px;
    grid-template-areas: "calendar calendar" "overview boxes";

    @media screen and (min-width: 1400px) {
        grid-template-areas: "overview calendar" "overview boxes";
    }
}

.overviewWrapper {
    grid-area: overview;
    display: flex;
    flex-direction: column;
}

.calendarWrapper {
    grid-area: calendar;
}

.boxesWrapper {
    grid-area: boxes;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media screen and (min-width: 1150px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
