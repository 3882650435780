.container {
    //when we decide on a common button put the styles here
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0 ;
    outline: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}