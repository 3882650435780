.container {
    display: flex;
    margin-bottom: 10px;
}

.checkbox {
    margin-right: 15px;

    > label {
        color: #223A78;
        text-transform: uppercase;
        font-size: 18px;
    }

    > .checkbox {
        border: 2px solid #223A78;
    }
}