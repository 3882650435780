.container {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    grid-row-gap: 3px;
    display: none;
    
    &.enable {
        display: grid;
    }
}

.upArrow,
.downArrow {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid #aaaaaa;
    transition: border-bottom-color .3s;
    cursor: pointer;

    &.active {
        border-bottom-color: var(--color-blue);
    }
}

.downArrow {
    transform: rotate(180deg);
}