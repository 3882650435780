.container {
    display: grid;
    grid-column-gap: 10px;
    grid-auto-flow: column;
}

.radioCheck,
.radioX {
    > .checkbox {
        border-radius: 100%;
        background-color: #BEC0C2;
    }
}

.radioCheck {
    > input {
        &:checked ~ .checkbox {    
            background-color: #53A73E;
        }
    }
}

.radioX {
    > input {
        &:checked ~ .checkbox {
            background-color: #DB4228;
        }
    }
}
