.container {
    display: grid;
    grid-template-rows: min-content 1fr;
    height: 100%;
    gap: 20px;
}

.form {
    display: flex;
    padding-top: 20px;
}

.tableContainer{
    position: relative;
}

.table{
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}