.container {
    margin-bottom: 15px;
}

.subTitle {
    padding: 4px 8px;
    background-color: #8F8FB2;
    color: white;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
}

.grid {
    background-color: #DDDEDF;
    display: grid;
    grid-gap: 2px;

    >* {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 4px 2px;
    }

    >*:not(.gridHeader) {
        padding: 10px 2px;
        color: #797877;
    }
}

.topGrid {
    grid-template-columns: 1fr 1fr 1fr 7fr 2fr 2fr 2fr 2fr 2fr;
    margin-bottom: 12px;
    border-bottom: 2px solid #DDDEDF;
}

.bottomGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.gridHeader {
    font-weight: 700;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
}

.iconsWrapper {
    display: grid;
    grid-column-gap: 10px;
    grid-auto-flow: column;
}

.checkIconWrapper,
.xIconWrapper {
    position: relative;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkIconWrapper {
    background-color: #54A84C;
}

.xIconWrapper {
    background-color: #DB4228;
}