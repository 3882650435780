.container {
  height: 80px;
  width: 80px;
  border: 4px solid transparent;
  border-radius: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.number {
  font-size: 22px;
  line-height: 1;
  font-weight: 700;
}

.text {
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
}
