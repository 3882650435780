.container {
    display: grid;
    grid-template-columns: 150px auto;
    grid-gap: 2px;
    align-content: start	;
    background-color: #DDDEDF;
    border-top: 2px solid #DDDEDF;
    border-bottom: 2px solid #DDDEDF;

    > div, label {
        background-color: white;
    }

    > div:not(.labelClean) {
        padding-left: 20px;
    }
}

.labelClean {
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: #223A78;
}