.container {
    display: grid;
    grid-column-gap: 4px;
    grid-auto-flow: column;
    margin-right: 8px;
}

.button {
    height: 18px;
    width: 18px;
    color: white;
    font-size: 11px;
}